//Usado en el Create Customer y en Filtros 
const tipoActivity =[{
  value:"Visita",
  text:"Visita"
},
{
  value:"Llamado",
  text:"Llamado"
}
]

export default tipoActivity;
